import '../scss/custom.scss';
import '../scss/style.scss';
import '../styles/globals.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Layout from '../Client/Components/Layout';
import { Provider, useDispatch } from 'react-redux';
import withRedux from 'next-redux-wrapper';

import store from '../redux/store';
import setupAxios from '../redux/setupAxios';
import axios from 'axios';
import { useEffect } from 'react';
import { login } from '../redux/Auth/authSlice';
import Cookies from 'universal-cookie';
import { useRouter } from 'next/router';

import '../styles/nprogress.css';
import nprogress from 'nprogress';
import { Toaster } from "react-hot-toast";
// setup axios with state
setupAxios(axios, store);

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  //setup token on load page
  const dispatch = useDispatch();
  useEffect(() => {
    const cookie = new Cookies();
    const authToken = cookie.get('authToken');
    // const authToken = window.localStorage.getItem("authToken");

    if (authToken) {
      dispatch(login({ authToken: authToken, isLogin: true }));
    }
  }, []);

  //progress bar in top
  useEffect(() => {
    const handleStart = (url) => {
      console.log(`Loading: ${url}`);

      nprogress.start();
    };
    const handleStop = () => {
      nprogress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  //layout

  const getLayout = Component.getLayout || ((page) => page);
  return (
    <Provider store={store}>
      <Toaster position="top-center" reverseOrder={false} />
      {getLayout(<Component {...pageProps} />)}
    </Provider>
  );
}

// /makeStore function that returns a new store for every request
const makeStore = () => store;

export default withRedux(makeStore)(MyApp);
