import { combineReducers } from "redux";
import authSlice from "./Auth/authSlice";
import profileSlice from "./Profile/profileSlice";


const rootReducer = combineReducers({
    auth : authSlice.reducer,
    profile : profileSlice.reducer
})

export default rootReducer;