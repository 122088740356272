import { createSlice } from '@reduxjs/toolkit';

// const authToken = window.localStorage.getItem("authToken");
const initAuthState = {
  //   authToken: authToken ? authToken : undefined,
  authToken: undefined,
  isLogin: false,
  firstName: 'Sanjay',
  userName : '',
  error: null,
  iframeLoader: 0,
};
const authSlice = createSlice({
  name: 'auth',
  initialState: initAuthState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.errorMessage;
    },
    startCall: (state, action) => {
      state.error = null;
    },
    //Login user
    login: (state, action) => {
      state.authToken = action.payload.authToken;
      state.isLogin = true;
      state.error = null;
    },
    //Register user
    register: (state, action) => {
      state.authToken = action.payload.authToken;
      state.isLogin = true;
      state.error = null;
    },
    // reload iframe
    loadIframe: (state, action) => {
      state.iframeLoader = state.iframeLoader + 1;
    },
  },
});

export const { login, register, loadIframe } = authSlice.actions;

export default authSlice;
