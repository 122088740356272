import Image from "next/image";
import Link from "next/link";

export default function Logo() {
  return (
    <Link href={"/"} passHref>
      <a><Image src="/1links.svg" width="109" height={'40'} alt="1LinksApp Logo" /></a>
    </Link>
  );
}
