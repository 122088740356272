export default function setupAxios(axios, store) {
  console.log("axios state", store.getState());
  // console.log("axios state", window.localStorage.getItem('authToken'));
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
