import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
import Logo from './Logo';

export default function Header() {
  useEffect(() => {
    console.log('Header bar rendered');
  }, []);

  return (
    <div className="container pt-4">
      <header className="px-3">
        <div className="row">
          <div className="col-4">
            <div className="logo">
              <Logo />
              {/* <Image src="/1links.svg" width="109" height={'40'} alt="1Links App" layout="fill" /> */}
            </div>
          </div>
          <div className="col-8 text-end">
            <div className="btn btn-link beamerButton beamer_beamerSelector">What&apos;s new</div>

            <Link href="/login">
              <a className="btn btn-outline-dark me-3">Login</a>
            </Link>
            <Link href="/register">
              <a className="btn btn-dark">Register</a>
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}
