import { createSlice } from '@reduxjs/toolkit';

// const authToken = window.localStorage.getItem("authToken");
const initProfileState = {
  fullName: '',
  userName: '',
  email: '',
  isEmailVerified : 1,
  analyticsSiteID : 0,
  error: null,
};
const profileSlice = createSlice({
  name: 'profile',
  initialState: initProfileState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.errorMessage;
    },
    startCall: (state, action) => {
      state.error = null;
    },
    //Login user
    profileLoad: (state, action) => {
      state.fullName = action.payload.fullName;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.isEmailVerified = action.payload.isEmailVerified;
      state.analyticsSiteID = action.payload.analyticsSiteID;
      state.error = null;
    },
  },
});

export const { profileLoad } = profileSlice.actions;

export default profileSlice;
